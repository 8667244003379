/* @import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

p {
  font-size: 16px;
  font-weight: 200;
  font-family: Helvetica, Arial, sans-serif;
  color: rgba(255, 255, 255, 0.98);
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

a {
  color: white;
}

input {
  color: #000;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (max-width: 768px) {
  .header {
    height: 6vh;
    /* width: 100%; */
    width: 430px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 40;
    padding-bottom: 4px;
  }

  .landing {
    width: 100%;
    height: 100vh;
  }

  .landing-text {
    letter-spacing: 5px;
    margin-top: 10px;
    font-size: 12px;
    margin-bottom: 15px;
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .contentLanding {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing-logo {
    /* width: 360px; */
    height: 18px;
    margin-top: 12px;
    /* margin-right: 10px; */
  }

  .header-img {
    height: 14px;
    align-items: flex-start;
  }

  .contentH1 {
    font-size: 20px;
    font-weight: 500;
    font-family: Helvetica, Arial, sans-serif;
    color: rgba(255, 255, 255, 0.98);
    text-transform: uppercase;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .landing-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .custom-button {
    width: 160px;
    height: 30px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: none;
    border: 1px solid white;
    cursor: pointer;
    color: white;
    margin-top: 10px;
    border-radius: 5px;
  }

  .custom-button:hover {
    transform: scale(1.015);
    opacity: 1;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: white;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    width: 200px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
    margin-top: 10px;
    font-weight: 500;
    transition: background-color 0.4s;
  }

  .background-container {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100vh;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .background-container::-webkit-scrollbar {
    display: none;
  }

  .background-container-faq {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100%;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .section-main {
    scroll-snap-align: start;
    height: 100vh;
    position: relative;
    overflow: hidden;
    margin: 0px auto;
  }

  .fixed-buttons-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
  }

  .centered {
    justify-content: center;
  }

  .header-container {
    width: 400px;
    background: rgb(240, 241, 242);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .toolbar-button {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: none;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: opacity 2.3s ease, transform 3.3s ease;
  }

  .toolbar-button-black {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: black;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }

  .toolbar-button.visible {
    opacity: 1;
    transform: scale(1);
  }

  .other-button:hover {
    background-color: white;
    color: black;
  }

  .support-button:hover {
    background-color: white;
    color: black;
  }

  .shadow-4 {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* tabsolution */
  .solution-tabs {
    margin-top: 100px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .tab-container {
    width: 100%;
    height: 300px;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .header-intro {
    margin-top: 20px;
    color: white;
    margin-left: 40px;
  }

  /* //star */
  .ssl-container-out {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    /* justify-content: center;
    align-items: center; */
    /* margin-top: auto;
    margin-bottom: auto; */
    /* height: 100vh; */
  }

  .ssl-header {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    font-size: 16px;
  }

  .ssl-container {
    display: flex;
    flex-direction: column;
    /* margin: 0px auto;
    justify-content: center;
    align-items: center; */
    /* width: 1200px; */
  }

  .ssl-img-cont {
    display: none;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100%;
    /* margin-left: 100px;
    margin-bottom: 20px; */
  }

  .ssl-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin-top: auto;
    margin-bottom: auto; */
    /* height: 620px;
    width: 850px;
    margin-left: 40px; */
  }

  .tabPane {
    font-size: 10px;
  }

  .pr-img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
  }

  .pr-p {
    margin-bottom: 10px;
    text-align: justify;
    width: 360px;
    font-size: 12px;
    font-family: "'Open Sans',blinkmacsystemfont,'Segoe UI',roboto,'Helvetica Neue',helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei',simsun,sans-serif";
  }

  /* product component */

  .starlink-card {
    background: none;
    border-radius: 12px;
    width: 400px;
  }

  /* .st-padding {
    padding: 20px;
  } */

  .starlink-card:hover {
    box-shadow: 0px 0px 10px 0px white(0, 0, 0, 0.5);
  }

  .st-tab {
    color: white;
    width: 650px;
    margin-left: 40px;
  }

  .white-color {
    color: white;
    font-size: 9px;
    margin-right: -10px;
  }
  .st-tab-text {
    margin-left: -5px;
  }

  .starlink-li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: -60px;
  }

  .flex-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    /* margin-bottom: 20px; */
  }

  .icon-cell {
    width: 80px;
    height: 80px;
  }

  .icon-cell2 {
    width: 80px;
    height: 80px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }

  .starlink-desc {
    margin-top: 10px;
    width: 70%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
  }

  /* .flex-img-container {
    width: 100px;
    height: 400px;
    margin-top: 65px;
    margin-left: 20px;
  } */
  .starlink-desc-fizik {
    margin-top: 10px;
    width: 85%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 40px;
  }

  .starlink-icon {
    max-width: 100%;
    max-height: 100%;
  }

  .starlink-p1 {
    width: 30%;
    margin-left: 20px;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .starlink-p {
    width: 70%;
    justify-content: flex-start;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .padding-flex {
    padding: 20px;
  }

  /* intro */
  .pr-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  }

  .pr-50-inline {
    width: 100%;
    padding: 70px;
    position: relative;
    display: inline-block;
  }

  .pr-header {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
  }

  .pr-img {
    width: 450px;
    height: 450px;
    border-radius: 10px;
  }

  .pr-desc {
    width: 100%;
    margin-right: 60px;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    text-align: justify;
  }

  .pr-p {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
  }

  .pr-p-2 {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
    font-weight: 300;
    font-size: 14px;
  }

  .padding-40 {
    padding: 40px;
  }

  .tech-cont {
    display: flex;
    flex-direction: column;
    width: 380px;
  }

  .tech-cont-list {
    display: flex;
    flex-direction: row;
    font-size: 10px;
  }

  .list-a {
    font-size: 12px;
    width: 160px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .list-b {
    width: 220px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  /* .tech-ul-1 {
    width: 280px;
    font-size: 14px;
  }
  .tech-ul-2 {
    width: 100%;
    font-size: 12px;
  } */
  .fizik-cont {
    display: flex;
    flex-direction: row;
  }

  .fz-1 {
    margin-top: 100px;
    margin-left: 10px;
    font-size: 12px;
  }

  .fz-2 {
    width: 200px;
    margin-left: -30px;
  }

  .fz-3 {
    text-align: center;
    margin-left: 40px;
    width: 160px;
    font-size: 10px;
  }

  .fz-4 {
    width: 125px;
    height: 10px;
    margin-left: 40px;
  }

  .fz-img {
    width: 120px;
    height: 180px;
  }

  .fz-text {
    text-align: center;
    font-size: 12px;
  }

  .fz-c-2 {
    width: 160px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .width-150 {
    width: 150px;
  }

  .fz-img-2 {
    width: 220px;
    height: 50px;
  }

  .fz-top-t {
    text-align: end;
    justify-content: flex-end;
    width: 60px;
    margin-top: -90px;
    font-size: 10px;
  }

  .fz-5 {
    margin-top: 120px;
    margin-left: 90px;
    font-size: 10px;
  }

  .fz-6 {
    margin-top: 12px;
  }

  /* footer */

  .footer {
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0px;
  }

  .footer-container2 {
    margin-top: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    width: 1200px;
    /* width: "100%"; */
    margin: 0px auto;
    bottom: 0px;
  }

  .flex-row2 {
    display: flex;
    flex-direction: row;
  }

  .footer-sub {
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: 100px; */
  }

  .f-12-family {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
  }

  .w-250-flex {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footers {
    width: 1100px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .footer-name {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 20px;
    color: white;
  }

  .f-line {
    border-left: 1px solid white;
    margin-right: 20px;
    margin-left: 3px;
  }

  /* footerL */
  .fl-container {
    height: 100%;
    width: 100%;
    background-color: #f7f9fa;
  }

  .fl-sub {
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    /* margin: 0px auto; */
  }

  .fl-sub-1 {
    display: flex;
    flex-direction: row;
    height: 18px;
    margin-left: 10px;
    width: 120px;
    margin-bottom: 10px;
  }

  .fl-img {
    height: 15px;
  }

  .fl-txt {
    color: #000;
    padding: 5px;
    font-size: 10px;
    margin-top: -2px;
    /* width: 80px; */
  }

  .fl-sub-2 {
    display: flex;
    flex-direction: row;
    height: 10px;
    width: 20px;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    margin-top: 5px;
    margin-right: -15px;
  }

  .fl-imgg {
    width: 20px;
  }

  .fl-img-2 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
  }

  .fl-img-3 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    margin-right: -10px;
    margin-left: 5px;
  }

  .fl-hr {
    /* margin-top: 10px; */
  }

  .fl-hr-s {
    color: #a3abb1;
    display: flex;
    margin: 0px auto;
    justify-content: center;
    width: 1390px;
    margin-left: 200px;
    height: 1px;
  }

  .fl-cont-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    /* width: 1400px; */
  }

  .fl-add {
    color: #000;
    padding: 5px;
    width: 390px;
    font-size: 10px;
    margin-left: 15px;
  }

  .fl-add-2 {
    color: #000;
    /* padding: 5px; */
    width: 300px;
    font-size: 10px;
    margin-left: 15px;
  }

  .fl-phone {
    color: #000;
    padding: 10px;
    margin-right: -10px;
    width: 120px;
    font-size: 10px;
  }

  /* product list */

  .bfl-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    margin: 0 10px;
    overflow: hidden;
  }

  .bfl-card-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .bfl-card-img2 {
    display: block;
    margin-top: 60px;
    margin-left: 60px;
    width: 60%;
    height: 60%;
  }

  .bfl-card-desc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    padding: 20px;
  }

  /* faq */

  .faq2-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    /* margin-top: 75px; */
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .faq2-container {
    padding: 20px;
    /* margin-top: 40px; */
    background-color: #000312;
    /* max-width: 1200px; */
    height: 65vh;
    overflow-y: auto;
    /* margin-bottom: 30px; */
  }

  .faq2-section::-webkit-scrollbar {
    display: none;
  }

  .faq2-h3 {
    font-size: 18px;
    line-height: 25px;
    color: white;
    margin-top: auto;
  }

  .faq2-ul {
    flex-basis: 58.333%;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .faq2-li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    -webkit-transition-property: border;
    transition-property: border;
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .faq2-div {
    transform: rotate(180deg);
    transition-property: all;
    transition-duration: 0.3s, 0.3s;
  }

  .faq2-q {
    text-align: left;
    color: white;
    font-size: 12px;
    width: 100%;
    justify-content: center;
    margin-bottom: 0rem;
  }

  .faq2-q.clicked {
    font-weight: 500;
    color: #000;
  }

  .faq2-desc {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 90%;
    font-size: 12px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }

  .faq2-desc.clicked {
    background-color: white;
  }

  .paragraph.clicked {
    background-color: white;
    color: #000;
  }

  .faq2-p {
    color: black;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 0px !important;
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start; */
  }

  .faq2-footer {
    width: 100%;
    height: 10px;
    background-color: #f7f9fa;
    margin-top: auto;
    position: relative;
    bottom: 0;
  }

  .faq2-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    /* max-width: 1200px; */
    /* margin: 0 auto; */
  }

  .margin-left-28 {
    margin-left: 10px;
  }

  /* Footer text styles */
  .faq2-footer-text {
    color: black;
    /* padding: 10px; */
    font-size: 9px;
  }

  .faq-number {
    width: 100px;
  }

  /* Footer contact styles */
  .faq2-footer-contact {
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .faq2-footer-contact img {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
  }

  .bf-select-item {
    border-radius: 10px;
    width: 88%;
    font-family: "Open Sans", blinkmacsystemfont, "Segoe UI", roboto,
      "Helvetica Neue", helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", simsun, sans-serif;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .bf-col-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid lightgrey;
    /* padding: 10px; */
  }

  .bf-col-1:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-col-1.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 180px;
    margin-top: 20px;
    margin-left: 20px;
    border: 1px solid #808080;
    border-radius: 10px;
  }

  .bf-c-main:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-c-main.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .bf-col-2 {
    display: flex;
    flex-direction: row;
    padding: 30px;
    border: 1px solid #f0f1f2;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease;
  }

  .bf-col-2:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-col-2.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-row-1 {
    /* position: relative; */
    /* max-width: 1320px; */
    /* width: 100%; */
    padding: 20px;
    margin: 0px auto;
  }

  .ant-tabs-ink-bar {
    /* display: none !important; */
    border: 1px solid #1d1d1f !important;
  }

  .counter-container {
    display: flex;
    align-items: center;
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: 30px;
  }

  .counter-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
  }

  .counter-container input {
    width: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: none;
    border-top: none;
    border-radius: 0%;
    color: #000;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>div>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    content: "";
  }

  .link-text {
    width: 160px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
  }

  .link-text:hover {
    color: blue;
  }

  .marginTop5 {
    margin-top: 5px;
  }

  /* buymain */
  .buy-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 20px;
  }

  .buy-menu:hover {
    background-color: #f2f2f2;
  }

  .buy-menu.clicked {
    background-color: #f2f2f2;
  }

  .buy-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e3e5;
    height: 300px;
    /* background-color: #f2f2f2; */
    padding: 20px;
    /* height: 550px; */
    /* width: 400px; */
  }

  .buy-second-item {
    margin-top: 80vh;
  }

  .buy-third-item {
    margin-top: 40vh;
  }

  .buy-item:hover {
    background-color: #fafafa;
  }

  .buy-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f2f2f2; */
    background-color: #d7d3d3;
    padding: 10px;
    height: 100%;
    width: 100%;
  }

  .buy-grid-item:hover {
    background-color: #fafafa;
  }

  .show-all {
    margin-bottom: 7px;
  }

  .show-all:hover {
    text-decoration: underline;
  }

  .videofull {
    width: 100%;
    height: 100%;
  }

  .l-menu {
    cursor: pointer;
  }

  .l-menu:hover {
    text-decoration: underline;
  }

  .buy-button-container {
    height: 8vh;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f9fa;
    color: white;
    z-index: 100;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  .bfooter-container {
    height: 6vh;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: #a3abb1;
    z-index: 100;
  }

  .buy-filter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e4e6e8;
    padding: 20px;
  }

  .buy-filter-item-img {
    width: 160px;
  }

  .buy-filter-item:hover {
    background-color: #fafafa;
  }

  .buy-main-filter-item {
    margin-left: 240px;
    margin-top: 20px;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 5px;
  }

  .buy-main-filter-item-l {
    margin-bottom: 10px;
    font-size: 12px;
    width: 140px;
  }

  .buy-main-filter-item-l:hover {
    font-weight: 600;
  }

  .buy-main-filter-item-l.selected {
    font-weight: 600;
  }

  .buy-filter-img {
    height: 100%;
    width: 100%;
  }

  .buy-filter-img:hover {
    height: 100%;
    width: 100%;
  }

  .vertical-line {
    width: 0.2px;
    height: 680px;
    margin-top: 20px;
    background-color: grey;
  }

  /* buy-main  */
  .buy-out {
    max-width: 400px;
    margin: 0px auto;
    height: 100vh;
  }

  .buy-main-container {
    background-color: white;
    width: 100%;
    /* width: 1400px; */
    /* margin: 0px auto; */
    flex: 1;
  }

  .buy-main-d-f {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    margin: 0px auto;
  }

  .buy-menu-dropdown-container {
    margin-top: 80px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
  }

  .buy-menu-dropdown {
    /* width: 180px; */
    height: 30px;
    border: none;
    font-size: 12px;
  }

  .bm-filter {
    display: none;
  }

  .buy-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 3px;
  }

  .bm-container-filter {
    display: flex;
    flex-direction: column;
    /* margin-left: 40px; */
    height: 300px;
    padding: 10px;
  }

  .bm-container-filter-item {
    display: flex;
    flex-direction: row;
    height: 400px;
    justify-content: center;
    align-items: center;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-18 {
    font-size: 12px;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-left-40 {
    margin-left: 40px;
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .margin-top-40 {
    margin-top: 40px;
  }

  .margin-top-18 {
    margin-top: 18px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .height-550 {
    height: 550px;
  }

  .height-400 {
    height: 400px;
  }

  .filter {
    filter: grayscale(100%) brightness(40%);
  }

  .buy-main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .display-row {
    display: flex;
    flex-direction: row;
  }

  .display-column {
    display: flex;
    flex-direction: column;
  }

  .b-link-text {
    margin-left: 10px;
    margin-top: -2px;
    height: 10px;
    width: 10px;
  }

  .buy-main-name {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }

  .amount {
    font-weight: 600;
    font-size: 12px;
    height: 20px;
  }

  .buy-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 7px;
    padding: 10px;
    margin-left: -10px;
    /* max-width: 400px; */
  }

  .buy-img-cont {
    height: 100px;
    width: 100px;
    /* padding: 10px; */
  }

  .buy-item-img {
    height: 200px;
    width: 200px;
  }

  .buy-item-name {
    font-size: 12px;
    max-height: 80px;
    margin-top: 10px;
    text-align: center;
  }

  .ic-45 {
    height: 45px;
    width: 45px;
  }

  .add-list {
    margin-top: 40px;
    width: 100%;
    /* max-width: 1400px; */
    margin: 0px auto;
  }

  .add-title {
    margin-left: 40px;
    color: #000;
  }

  /* .add-container {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
    gap: 7px;
    padding: 10px;
  } */
  .add-img-cont-1 {
    display: none;
  }

  .i-cs {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    height: 100%;
    width: 100%;
  }

  .add-sub {
    display: grid;
    grid-template-columns: repeat(2, 3fr);
  }

  .add-img {
    height: 100%;
    width: 100%;
    padding: 10px;
    object-fit: cover;
  }

  .add-c-1 {
    margin-left: 40px;
    margin-top: 20px;
  }

  /* buy-flight */
  .bs-label {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 10px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
  }

  .bs-label.selected {
    background-color: rgba(0, 0, 0, 0.85);
    border: none;
    color: white;
  }

  .bs-label-img-cont {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .bs-tab-content {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: "#ececec";
  }

  .bs-second-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  .href-text {
    padding: 50px;
    margin-bottom: -50px;
    margin-top: 40px;
  }

  .bs-container {
    display: flex;
    flex-direction: column;
    /* max-width: 1400px; */
    margin: 0px auto;
    /* max-height: 740px; */
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .width-656 {
    width: 656px;
  }

  .width-100 {
    width: 100%;
  }

  .height-500 {
    height: 500px;
  }

  .height-740 {
    height: 740px;
  }

  .bs-second-container-header {
    font-size: 20px;
  }

  .bs-header {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 4px;
    margin-bottom: 10px;
  }

  .bs-icon {
    width: 20px;
    height: 20px;
  }

  .br-10 {
    border-radius: 5px;
  }

  .br-grey {
    border: 1px solid grey;
  }

  .bs-header-text {
    background-color: #f7f9fa;
    padding: 10px;
    border-radius: 10px;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .color-3b3e40 {
    color: #3b3e40;
  }

  .bs-check-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    list-style: none;
    margin-bottom: 10px;
  }

  .bf-container-tabs {
    width: 100%;
  }

  .tab-height {
    height: 500px;
  }

  .bs-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
  }

  .bs-check-cont {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }

  .bs-check-cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 40px;
    width: 320px;
  }

  .bs-check-cont-2-name {
    font-size: 12px;
    text-align: start;
    margin: 10px 0px 0px;
    color: black;
  }

  .bs-check-cont-2-amount {
    font-size: 14px;
    text-align: center;
    color: #000;
  }

  .bs-hr {
    /* width: 1400px; */
    color: #3b3e40;
    display: flex;
    margin: 0px auto;
  }

  .mr-30 {
    padding: 30px;
  }

  .bs-s-cont {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: 0px auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bs-s-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    width: 360px;
  }

  .bs-s-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    text-align: justify;
    font-size: 14px;
    width: 360px;
    text-align: justify;
  }

  .tech {
    font-size: 12px;
  }

  .bs-s-dot {
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  .bs-s-sub {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 300px;
    height: 240px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: flex-end;
    margin: 0px auto;
  }

  .bs-s-sub-header {
    font-size: 18px;

    display: flex;
    flex-direction: column;
  }

  .bs-video-cont {
    display: flex;
    flex-direction: column;
    height: 500px;
    /* margin-top: 20px; */
  }

  .bs-video-cont-img-c {
    /* margin-left: 40px; */
    padding: 20px;
  }

  .bs-video-cont-img-d {
    /* margin-left: 40px; */
    padding: 20px;
  }

  .bs-video-cont-img-1 {
    width: 100%;
  }

  .bs-video-cont-img {
    /* margin-top: 30px; */
    width: 100%;
  }

  .bs-price-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: -20px;
  }

  .bs-pric-txt {
    text-align: center;
    font-size: 14px;
    color: #000;
  }

  .bs-button2 {
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 10px;
    bottom: 0px;
    font-size: 8px;
    width: 108px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .bs-button3 {
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 10px;
    bottom: 0px;
    font-size: 8px;
    width: 70px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .bs-button {
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    font-size: 10px;
    height: 20px;
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* AllProduct.css */

  .all-product-content {
    background-color: white;
    /* width: 1400px; */
    margin: 0 auto;
  }

  .all-product-main {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
    margin: 0 auto;
  }

  .all-filter-container {
    display: none;
  }

  .all-filter-second {
    display: flex;
    flex-direction: row;
  }

  .all-product-sidebar {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .all-product-sidebar-h3 {
    /* font-family: "Helvetica, Arial, sans-serif"; */
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .all-product-sidebar-secondary {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -10px;
    /* margin-top: 40px; */
  }

  .all-product-sidebar-item {
    width: 300px;
  }

  .all-product-sidebar-item-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .all-product-sidebar-item-img {
    height: 15px;
    width: 15px;
    margin-left: 20px;
  }

  .all-product-sidebar-item-radio {
    width: 100%;
    margin-top: 10px;
  }

  .all-product-header-2 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: -10px;
  }

  .all-product-main-content {
    /* margin-left: 40px; */
  }

  .all-product-main-content-header {
    display: flex;
    padding: 10px;
    font-size: 12px;
    /* flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%; */
  }

  .all-product-main-content-header .filter-button {
    cursor: pointer;
    color: blue;
    background-color: rgba(0, 0, 0, 0.04);
    width: 220px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .all-product-grid-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    margin-right: -20px;
  }

  .all-product-main-content-header .filter-item {
    display: flex;
    flex-direction: column;
  }

  .all-product-main-content-header .filter-item .selected {
    background-color: lightgray;
  }

  .all-product-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .all-product-item {
    cursor: pointer;
  }

  .all-product-item img {
    height: 100%;
    width: 100%;
    /* padding: 20px; */
  }

  .all-product-item-name {
    font-size: 14px;
    height: 50px;
    text-align: center;
    width: 140px;
    font-weight: 400px;
    margin-bottom: 20px;
  }

  .all-product-item-price {
    font-size: 18px;
    font-weight: 600px;
    margin-top: 30px;
  }

  .bs-row-1-cont {
    height: 22 0px;
  }

  /* rent */
  .rent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    /* margin-left: 40px; */
    /* margin-bottom: 5px; */
  }

  .rent-products {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-bottom: 20px;
  }

  .rent-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #e6e3e5; */
    background-color: #f2f2f2;
    /* height: 350px; */
    width: 100%;
  }

  .rent-item:hover {
    padding: 0px;
    height: 570px;
  }

  .rent-image {
    padding: 40px;
  }

  .rent-product-title {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: -20px;
    text-align: center;
  }

  .rent-item.selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #e6e3e5; */
    background-color: #f2f2f2;
    /* padding: 40px; */
    height: 550px;
    width: 400px;
  }

  .rent-check-cont {
    /* width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; */
    margin-left: 20px;
  }

  .rent-add-item {
    width: 360px;
    font-size: 10px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rent-price-detail {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }

  .rent-price-d {
    display: flex;
  }

  .sc-container {
    width: 400px;
    margin: 0px auto;
    /* padding: 80px; */
    margin-top: 80px;
    margin-left: 20px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }

  .sc-sub-container {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  .sc-sub-container-header {
    font-size: 14px;
  }

  .sc-check-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    list-style: none;
    /* margin-bottom: 10px; */
    /* width: 1400px; */
  }

  .sc-check-grid-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }

  .sc-check-grid-name {
    width: 100px;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 10px;
  }

  .sc-check-grid-name-image {
    display: none;
  }

  .sc-check-grid-name-text {
    color: black;
    margin-left: 20px;
    font-size: 10px;
  }

  .sc-check-grid-price {
    width: 100px;
    color: #000;
    font-size: 10px;
    text-align: center;
  }

  .sc-check-grid-price1 {
    width: 50px;
    color: #000;
    font-size: 10px;
    text-align: center;
  }

  .sc-check-grid-counter {
    width: 100px;
    height: 20px;
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 10px;
  }

  .sc-check-grid-counter1 {
    width: 60px;
    height: 20px;
    color: black;
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 10px;
  }

  .counter-button {
    margin-top: -8px;
    margin-left: -5px;
    /* height: 30px; */
    width: 15px;
    margin-right: 5px;
  }

  .sc-check-grid-total {
    width: 60px;
    color: #000;
    font-size: 10px;
    text-align: center;
  }

  .sc-check-grid-button {
    height: 20px;
    width: 20px;
    margin-top: -5px;
    margin-left: 5px;
    text-align: center;
  }

  .sc-form {
    display: flex;
    flex-direction: row;
    /* width: 1400px; */
    margin-right: auto;
    margin-left: auto;
    /* margin-top: 40px; */
    /* margin-bottom: 100px; */
  }

  :where(.css-dev-only-do-not-override-95jhdm).ant-form-item .ant-form-item-label>label {
    font-size: 12px;
    margin-bottom: -30px;
  }

  .contact-main {
    background-color: white;
    width: 100%;
    margin: 0px auto;
  }
  .contact-second {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .contact-third {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-img {
    height: 80px;
    width: 80px;
  }
  .contact-card {
    display: grid;
    /* grid-template-columns: repeat(5,1fr); */
    gap: 20px;
    list-style: none;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .c-card-item {
    padding: 16px;
  }
  .c-card-item-h {
   height:  160px;
  }
  .c-card-body {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .c-card-img {
    width: 40px;
    height: 20px;
  }
  .contact-address {
    display: flex;
    flex-direction: column;
  }
  .contact-address-t {
    margin-left: 10px;
    height: 70px;
  }
  .contact-address2 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .contact-a-img {
    height: 10px;
    width: 10px;
    align-items: self-start;
  }
  .contact-fb {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .contact-fb-embed {
    height: 520px;
    width: 460px;
  }
  .contact-insta-embed {
    height: 520px;
    width: 440px;
    /* margin-left: 30px; */
  }
  .contact-tw-embed {
    height: 520px;
    width: 440px;
    /* margin-left: 50px; */
  }

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1399px) {
  .header {
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 40;
    padding-bottom: 4px;
  }

  .landing {
    width: 100%;
    height: 100vh;
  }

  .landing-text {
    letter-spacing: 5px;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .contentLanding {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing-logo {
    width: 500px;
    height: 40px;
    margin-top: 12px;
    margin-right: 10px;
  }

  .header-img {
    height: 24px;
    align-items: flex-start;
  }

  .contentH1 {
    font-size: 20px;
    font-weight: 500;
    font-family: Helvetica, Arial, sans-serif;
    color: rgba(255, 255, 255, 0.98);
    text-transform: uppercase;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .landing-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .custom-button {
    width: 160px;
    height: 30px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: none;
    border: 1px solid white;
    cursor: pointer;
    color: white;
    margin-right: 10px;
  }

  .custom-button:hover {
    transform: scale(1.015);
    opacity: 1;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: white;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    width: 200px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
    margin-top: 10px;
    font-weight: 500;
    transition: background-color 0.4s;
  }

  .background-container {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100vh;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .background-container::-webkit-scrollbar {
    display: none;
  }

  .background-container-faq {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100%;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .section-main {
    scroll-snap-align: start;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /* max-width: 1400px; */
    margin: 0px auto;
    /* padding: 40px 75px; */
  }

  .fixed-buttons-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
  }

  .centered {
    justify-content: center;
  }

  .header-container {
    width: 100%;
    background: rgb(240, 241, 242);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .toolbar-button {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: none;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: opacity 2.3s ease, transform 3.3s ease;
  }

  .toolbar-button-black {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: black;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }

  .toolbar-button.visible {
    opacity: 1;
    transform: scale(1);
  }

  .other-button:hover {
    background-color: white;
    color: black;
  }

  .support-button:hover {
    background-color: white;
    color: black;
  }

  .shadow-4 {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* tabsolution */
  .solution-tabs {
    margin-top: 100px;
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }

  .tab-container {
    width: 1000px;
    height: 620px;
    margin-left: auto;
    margin-right: auto;
  }

  .header-intro {
    margin-top: 100px;
    color: white;
    margin-bottom: -80px;
  }

  /* //star */
  .ssl-container-out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 100vh;
  }

  .ssl-container {
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    width: 1200px;
  }

  .ssl-img-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    margin-left: 100px;
    margin-bottom: 20px;
  }

  .ssl-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 620px;
    width: 850px;
    margin-left: 40px;
  }

  .tabPane {
    font-size: 12px;
  }

  .pr-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .pr-p {
    margin-bottom: 10px;
    text-align: justify;
    /* font-size: 12px; */
    font-family: "'Open Sans',blinkmacsystemfont,'Segoe UI',roboto,'Helvetica Neue',helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei',simsun,sans-serif";
  }

  /* product component */

  .starlink-card {
    background: none;
    width: 650px;
    height: 440px;
    border-radius: 12px;
  }

  /* .st-padding {
    padding: 20px;
  } */

  .starlink-card:hover {
    box-shadow: 0px 0px 10px 0px white(0, 0, 0, 0.5);
  }

  .st-tab {
    color: white;
    width: 650px;
    margin-left: 40px;
  }

  .white-color {
    color: white;
  }

  .starlink-li {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 9px; */
  }

  .flex-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    /* margin-bottom: 20px; */
  }

  .flex-img-container {
    width: 100px;
    height: 400px;
    margin-top: 65px;
    margin-left: 20px;
  }

  .starlink-desc-fizik {
    margin-top: 10px;
    width: 85%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 40px;
  }

  .icon-cell {
    width: 100px;
    height: 80px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-cell2 {
    width: 60px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .starlink-icon {
    max-width: 100%;
    max-height: 100%;
  }

  .starlink-desc {
    margin-top: 10px;
    width: 70%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .starlink-p1 {
    width: 30%;
    margin-left: 20px;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .starlink-p {
    width: 70%;
    justify-content: flex-start;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .padding-flex {
    padding: 20px;
  }

  /* intro */
  .pr-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  }

  .pr-50-inline {
    width: 100%;
    padding: 70px;
    position: relative;
    display: inline-block;
  }

  .pr-header {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
  }

  .pr-img {
    width: 450px;
    height: 450px;
    border-radius: 10px;
  }

  .pr-desc {
    width: 100%;
    margin-right: 60px;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    text-align: justify;
  }

  .pr-p {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
  }

  .pr-p-2 {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
    font-weight: 300;
    font-size: 14px;
  }

  .padding-40 {
    padding: 40px;
  }

  .tech-cont {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .tech-ul-1 {
    width: 300px;
    font-size: 16px;
  }

  .tech-ul-2 {
    margin-left: 40px;
    font-size: 16px;
  }

  .fizik-cont {
    display: flex;
    flex-direction: row;
  }

  .fz-1 {
    margin-top: 100px;
    margin-left: 10px;
    font-size: 12px;
  }

  .fz-2 {
    width: 200px;
    margin-left: -30px;
  }

  .fz-3 {
    text-align: center;
    margin-left: 40px;
    width: 160px;
    font-size: 10px;
  }

  .fz-4 {
    width: 125px;
    height: 10px;
    margin-left: 40px;
  }

  .fz-img {
    width: 120px;
    height: 180px;
  }

  .fz-text {
    text-align: center;
    font-size: 12px;
  }

  .fz-c-2 {
    width: 160px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .width-150 {
    width: 150px;
  }

  .fz-img-2 {
    width: 220px;
    height: 50px;
  }

  .fz-top-t {
    text-align: end;
    justify-content: flex-end;
    width: 60px;
    margin-top: -90px;
    font-size: 10px;
  }

  .fz-5 {
    margin-top: 120px;
    margin-left: 90px;
    font-size: 10px;
  }

  .fz-6 {
    margin-top: 12px;
  }

  /* footer */

  .footer {
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0px;
  }

  .footer-container2 {
    margin-top: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    width: 1200px;
    /* width: "100%"; */
    margin: 0px auto;
    bottom: 0px;
  }

  .flex-row2 {
    display: flex;
    flex-direction: row;
  }

  .footer-sub {
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: 100px; */
  }

  .f-12-family {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
  }

  .w-250-flex {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footers {
    width: 1100px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .footer-name {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 20px;
    color: white;
  }

  .f-line {
    border-left: 1px solid white;
    margin-right: 20px;
    margin-left: 3px;
  }

  /* footerL */
  .fl-container {
    height: 100%;
    width: 100%;
    background-color: #f7f9fa;
  }

  .fl-sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin: 0px auto;
  }

  .fl-sub-1 {
    display: flex;
    flex-direction: row;
    height: 20px;
  }

  .fl-img {
    height: 20px;
  }

  .fl-txt {
    color: #000;
    padding: 10px;
    font-size: 14px;
    margin-top: -2px;
    margin-left: 28px;
    cursor: pointer;
  }

  .fl-sub-2 {
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
  }

  .fl-img-2 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
  }

  .fl-img-3 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    margin-left: 5px;
  }

  .fl-hr {
    margin-top: 10px;
  }

  .fl-hr-s {
    color: #a3abb1;
    display: flex;
    margin: 0px auto;
    justify-content: center;
    width: 1390px;
    margin-left: 200px;
    height: 1px;
  }

  .fl-cont-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    width: 1400px;
  }

  .fl-add {
    color: #000;
    padding: 10px;
    font-size: 14px;
  }

  .fl-phone {
    color: #000;
    padding: 10px;
    margin-right: -10px;
    font-size: 14px;
  }

  /* product list */

  .bfl-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    margin: 0 10px;
    overflow: hidden;
  }

  .bfl-card-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .bfl-card-img2 {
    display: block;
    margin-top: 60px;
    margin-left: 60px;
    width: 60%;
    height: 60%;
  }

  .bfl-card-desc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    padding: 20px;
  }

  /* faq */

  .faq2-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    margin-top: 75px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .faq2-container {
    margin-top: 40px;
    background-color: #000312;
    max-width: 1200px;
    height: 65vh;
    overflow-y: auto;
    margin-bottom: 30px;
  }

  .faq2-section::-webkit-scrollbar {
    display: none;
  }

  .faq2-h3 {
    font-size: 18px;
    line-height: 25px;
    color: white;
    margin-top: auto;
  }

  .faq2-ul {
    flex-basis: 58.333%;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .faq2-li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    -webkit-transition-property: border;
    transition-property: border;
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .faq2-div {
    transform: rotate(180deg);
    transition-property: all;
    transition-duration: 0.3s, 0.3s;
  }

  .faq2-q {
    text-align: left;
    color: white;
    font-size: 16px;
    width: 100%;
    justify-content: center;
    margin-bottom: 0rem;
  }

  .faq2-q.clicked {
    font-weight: 500;
    /* text-decoration: underline; */
    color: #000;
  }

  .faq2-desc {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    width: 1000px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .faq2-desc.clicked {
    background-color: white;
  }

  .paragraph.clicked {
    background-color: white;
    color: #000;
  }

  .faq2-p {
    color: black;
    font-size: 16px;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px !important;
  }

  .faq2-footer {
    width: 100%;
    height: 180px;
    background-color: #f7f9fa;
    margin-top: auto;
  }

  .margin-left-28 {
    margin-left: 28px;
  }

  .faq2-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* Footer text styles */
  .faq2-footer-text {
    color: black;
    padding: 10px;
    font-size: 14px;
  }

  .faq-number {
    margin-right: -20px;
  }

  /* Footer contact styles */
  .faq2-footer-contact {
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
  }

  .faq2-footer-contact img {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
  }

  .bf-select-item {
    border-radius: 10px;
    width: 99%;
    font-family: "Open Sans", blinkmacsystemfont, "Segoe UI", roboto,
      "Helvetica Neue", helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", simsun, sans-serif;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .bf-col-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid lightgrey;
    padding: 10px;
  }

  .bf-col-1:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-col-1.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 10px;
    border: 1px solid #808080;
    border-radius: 10px;
  }

  .bf-c-main:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-c-main.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bf-col-2 {
    display: flex;
    flex-direction: row;
    padding: 30px;
    border: 1px solid #f0f1f2;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease;
  }

  .bf-col-2:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-col-2.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-row-1 {
    /* position: relative; */
    max-width: 1320px;

    margin: 0px auto;
  }

  .ant-tabs-ink-bar {
    /* display: none !important; */
    border: 1px solid #1d1d1f !important;
  }

  .counter-container {
    display: flex;
    align-items: center;
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    height: 40px;
  }

  .counter-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }

  .counter-container input {
    width: 60px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: none;
    border-top: none;
    border-radius: 0%;
    color: #000;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>div>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    content: "";
  }

  .link-text {
    width: 200px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
  }

  .link-text:hover {
    color: blue;
  }

  .marginTop5 {
    margin-top: 5px;
  }

  /* buymain */
  .buy-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 20px;
  }

  .buy-menu:hover {
    background-color: #f2f2f2;
  }

  .buy-menu.clicked {
    background-color: #f2f2f2;
  }

  .buy-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e3e5;
    /* background-color: #f2f2f2; */
    padding: 20px;
    height: 550px;
    width: 400px;
  }

  .buy-second-item {
    margin-top: 0px;
  }

  .buy-third-item {
    margin-top: 0px;
  }

  .buy-item:hover {
    background-color: #fafafa;
  }

  .buy-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f2f2f2; */
    background-color: #d7d3d3;
    padding: 20px;
    height: 270px;
    width: 230px;
  }

  .buy-grid-item:hover {
    background-color: #fafafa;
  }

  .show-all {
    margin-bottom: 7px;
  }

  .show-all:hover {
    text-decoration: underline;
  }

  .videofull {
    width: 100%;
    height: 100%;
  }

  .l-menu {
    cursor: pointer;
  }

  .l-menu:hover {
    text-decoration: underline;
  }

  .buy-button-container {
    height: 8vh;
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 100;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  .footer-b-container {
    /* height: 8vh; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 100;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  .bfooter-container {
    height: 6vh;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: #a3abb1;
    z-index: 100;
  }

  .buy-filter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e4e6e8;
    padding: 20px;
  }

  .buy-filter-item:hover {
    background-color: #fafafa;
  }

  /* .buy-filter-item-img {
    width: 200px;
  } */
  .buy-main-filter-item {
    margin-left: 890px;
    margin-top: 220px;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 5px;
  }

  .buy-main-filter-item-l {
    margin-bottom: 10px;
    font-size: 14px;
    width: 200px;
  }

  .buy-main-filter-item-l:hover {
    font-weight: 600;
  }

  .buy-main-filter-item-l.selected {
    font-weight: 600;
  }

  .buy-filter-img {
    height: 100%;
    width: 100%;
  }

  .buy-filter-img:hover {
    height: 100%;
    width: 100%;
  }

  .vertical-line {
    width: 0.2px;
    height: 680px;
    margin-top: 20px;
    background-color: grey;
  }

  /* buy-main  */
  .buy-main-container {
    background-color: white;
    width: 1200px;
    margin: 0px auto;
  }

  .buy-main-d-f {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin: 0px auto;
  }

  .bm-filter {
    margin-top: 80px;
  }

  .buy-menu-dropdown-container {
    display: none;
  }

  .buy-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 3px;
  }

  .bm-container-filter {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    height: 550px;
  }

  .bm-container-filter-item {
    display: flex;
    flex-direction: row;
    height: 400px;
    justify-content: center;
    align-items: center;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-left-40 {
    margin-left: 40px;
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .margin-top-40 {
    margin-top: 40px;
  }

  .margin-top-18 {
    margin-top: 18px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .height-550 {
    height: 550px;
  }

  .height-400 {
    height: 400px;
  }

  .filter {
    filter: grayscale(100%) brightness(40%);
  }

  .buy-main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    margin-left: 40px;
    margin-bottom: 5px;
  }

  .display-row {
    display: flex;
    flex-direction: row;
  }

  .display-column {
    display: flex;
    flex-direction: column;
  }

  .b-link-text {
    margin-left: 10px;
    margin-top: -2px;
    height: 10px;
    width: 10px;
  }

  .buy-main-name {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }

  .amount {
    margin-top: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 4px;
    margin-bottom: 10px;
  }

  .buy-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
    margin-left: 7px;
  }

  .buy-img-cont {
    height: 150px;
    width: 150px;
    padding: 20px;
  }

  .buy-item-img {
    height: 250px;
    width: 250px;
    padding: 10px;
  }

  .buy-item-name {
    font-size: 14px;
    max-height: 60px;
    text-align: center;
    margin-bottom: 5px;
  }

  .ic-45 {
    height: 45px;
    width: 45px;
  }

  .add-list {
    margin-top: 40px;
    max-width: 1400px;
    margin: 0px auto;
  }

  .add-title {
    margin-left: 40px;
    color: #000;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    margin: 0px auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 60px;
  }

  .add-img-cont-1 {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
  }

  .i-cs {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    height: 100%;
    width: 100%;
  }

  .add-sub {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0px auto;
  }

  .add-img {
    height: 100%;
    width: 100%;
    padding: 30px;
    object-fit: cover;
  }

  .add-c-1 {
    margin-left: 120px;
  }

  /* buy-flight */
  .bs-label {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 10px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
  }

  .bs-label.selected {
    background-color: rgba(0, 0, 0, 0.85);
    border: none;
    color: white;
  }

  .bs-label-img-cont {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .bs-tab-content {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: "#ececec";
  }

  .href-cont {
    display: flex;
    flex-direction: row;
    width: 1400px;
  }

  .href-text {
    padding: 50px;
    margin-bottom: -50px;
    margin-top: 40px;
  }

  .bs-container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0px auto;
    max-height: 740px;
    overflow-y: auto;
  }

  .bs-second-container {
    display: flex;
    flex-direction: column;
    width: 656px;
    margin-left: 20px;
  }

  .width-656 {
    width: 656px;
  }

  .bf-container-tabs {
    width: 656px;
  }

  .height-740 {
    height: 740px;
  }

  .tab-height {
    height: 740px;
  }

  .bs-second-container-header {
    font-size: 32px;
  }

  .bs-header {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 4px;
    margin-bottom: 10px;
  }

  .bs-icon {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  .br-10 {
    border-radius: 10px;
  }

  .br-grey {
    border: 1px solid grey;
  }

  .bs-header-text {
    background-color: #f7f9fa;
    padding: 10px;
    border-radius: 10px;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .color-3b3e40 {
    color: #3b3e40;
  }

  .bs-check-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    list-style: none;
    margin-bottom: 10px;
  }

  .bs-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
  }

  .bs-check-cont {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .bs-check-cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 40px;
    width: 320px;
  }

  .bs-check-cont-2-name {
    font-size: 20px;
    text-align: start;
    margin: 10px 0px 0px;
    color: black;
  }

  .bs-check-cont-2-amount {
    font-size: 18px;
    text-align: center;
    color: #000;
  }

  .bs-hr {
    width: 1400px;
    color: #3b3e40;
    display: flex;
    margin: 0px auto;
  }

  .mr-30 {
    padding: 30px;
  }

  .bs-s-cont {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0px auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bs-s-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
  }

  .bs-s-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    text-align: justify;
    font-size: 14px;
  }

  .bs-s-dot {
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  .bs-s-sub {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 300px;
    height: 240px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: flex-end;
    margin: 0px auto;
  }

  .bs-s-sub-header {
    font-size: 22px;
    display: flex;
    flex-direction: column;
  }

  .bs-video-cont {
    display: flex;
    flex-direction: row;
    width: 1400px;
    height: 500px;
    margin: 0px auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .bs-video-cont-img-c {
    margin-left: 40px;
  }

  .bs-video-cont-img-1 {
    width: 740px;
    height: 100%;
  }

  .bs-video-cont-img {
    width: 540px;
    height: 100%;
    margin-left: 10px;
  }

  .bs-price-cont {
    width: 1520px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .bs-pric-txt {
    font-size: 24px;
    color: #000;
  }

  .bs-button {
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    bottom: 0px;
  }

  .bs-button2 {
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    bottom: 0px;
  }

  /* AllProduct.css */

  .all-product-container {
    height: 140vh;
  }

  .all-product-content {
    background-color: white;
    width: 800px;
    margin: 0 auto;
  }

  .all-product-header {
    margin-top: 80px;
  }

  .all-product-main {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin: 0 auto;
  }

  .all-filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
  }

  .all-filter-second {
    display: none;
  }

  .all-product-sidebar {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .all-product-sidebar-h3 {
    /* font-family: "Helvetica, Arial, sans-serif"; */
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .all-product-sidebar-secondary {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -10px;
    /* margin-top: 40px; */
  }

  .all-product-sidebar-item {
    width: 300px;
  }

  .all-product-sidebar-item-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .all-product-sidebar-item-img {
    height: 15px;
    width: 15px;
    margin-left: 20px;
  }

  .all-product-sidebar-item-radio {
    width: 100%;
    margin-top: 10px;
  }

  .all-product-header-2 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: -10px;
  }

  .all-product-main-content {
    margin-left: 40px;
  }

  .all-product-main-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }

  .all-product-main-content-header .filter-button {
    cursor: pointer;
    color: blue;
    background-color: rgba(0, 0, 0, 0.04);
    width: 220px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .all-product-grid-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
  }

  .all-product-main-content-header .filter-item {
    display: flex;
    flex-direction: column;
  }

  .all-product-main-content-header .filter-item .selected {
    background-color: lightgray;
  }

  .all-product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .all-product-item {
    cursor: pointer;
  }

  .all-product-item img {
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .all-product-item-name {
    font-size: 14px;
    height: 50px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    font-weight: 400px;
  }

  .all-product-item-price {
    font-size: 18px;
    font-weight: 600px;
  }

  .bs-row-1-cont {
    margin-right: 30px;
    height: 518px;
  }

  /* rent */
  .rent-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    margin-left: 40px;
    margin-bottom: 5px;
  }

  .rent-products {
    width: 1060px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    margin-left: auto;
    margin-right: auto;
  }

  .rent-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #e6e3e5; */
    background-color: #f2f2f2;
    padding: 40px;
    height: 550px;
    width: 500px;
  }

  .rent-item:hover {
    padding: 0px;
    height: 570px;
  }

  .rent-item.selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #e6e3e5; */
    background-color: #f2f2f2;
    /* padding: 40px; */
    height: 550px;
    width: 400px;
  }

  .rent-check-cont {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .rent-add-item {
    width: 460px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rent-range {
    border: 3px solid black;
  }

  .sc-container {
    width: 100%;
    margin: 0px auto;
  }

  
  .contact-main {
    background-color: white;
    width: 1000px;
    margin: 0px auto;
  }
  .contact-second {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .contact-third {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-img {
    height: 80px;
    width: 80px;
  }
  .contact-card {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px;
    list-style: none;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .c-card-item {
    padding: 16px;
  }
  .c-card-item-h {
   height:  160px;
  }
  .c-card-body {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .c-card-img {
    width: 40px;
    height: 20px;
  }
  .contact-address {
    display: flex;
    flex-direction: column;
  }
  .contact-address-t {
    margin-left: 10px;
    height: 70px;
  }
  .contact-address2 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .contact-a-img {
    height: 10px;
    width: 10px;
    align-items: self-start;
  }
  .contact-fb {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }
  .contact-fb-embed {
    height: 520px;
    width: 460px;
  }
  .contact-insta-embed {
    height: 520px;
    width: 440px;
    margin-left: 30px;
  }
  .contact-tw-embed {
    height: 520px;
    width: 440px;
    margin-left: 50px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .ssl-header {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
  }

  .header {
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 40;
    padding-bottom: 4px;
  }

  .header-container {
    width: 1160px;
    background: rgb(240, 241, 242);
    display: flex;
    align-items: center;
    margin-top: 2vh;
    justify-content: space-between;
  }

  .faq2-footer {
    height: 11vh;
    background-color: #f7f9fa;
    margin-top: auto;
  }

  .faq2-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    max-width: 1160px;
    margin: 0 auto;
  }

  .buy-out {
    max-width: 1160px;
    margin: 0px auto;
    height: 100vh;
  }

  .buy-main-container {
    background-color: white;
    margin: 0px auto;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    overflow-y: auto;
  }

  .buy-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    /* padding: 20px; */
    font-size: 12px;
    width: 200px;
  }

  .ic-45 {
    height: 35px;
    width: 35px;
  }

  .buy-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e3e5;
    padding: 20px;
    height: 400px;
    width: 300px;
  }

  .buy-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
    /* margin-left: 7px; */
  }

  .buy-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f2f2f2; */
    background-color: #d7d3d3;
    /* padding: 20px; */
    /* height: 30px; */
    width: 160px;
  }

  .buy-img-cont {
    height: 70px;
    width: 70px;
    padding: 10px;
  }

  .buy-item-img {
    height: 240px;
    padding: 10px;
  }

  .buy-item-name {
    font-size: 12px;
    max-width: 100px;
    max-height: 60px;
    text-align: center;
    margin-bottom: 5px;
  }
}

@media (min-width: 1401px) {
  .header {
    height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 40;
    padding-bottom: 4px;
  }

  .landing {
    width: 100%;
    height: 100vh;
  }

  .landing-text {
    letter-spacing: 5px;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .contentLanding {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landing-logo {
    width: 500px;
    height: 40px;
    margin-top: 12px;
    margin-right: 10px;
  }

  .header-img {
    height: 24px;
    align-items: flex-start;
  }

  .contentH1 {
    font-size: 20px;
    font-weight: 500;
    font-family: Helvetica, Arial, sans-serif;
    color: rgba(255, 255, 255, 0.98);
    text-transform: uppercase;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .landing-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .custom-button {
    width: 160px;
    height: 30px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: none;
    border: 1px solid white;
    cursor: pointer;
    color: white;
    margin-right: 10px;
  }

  .custom-button:hover {
    transform: scale(1.015);
    opacity: 1;
    border: 1px solid black;
    cursor: pointer;
    color: black;
    background-color: white;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    width: 200px;
    border-radius: 5px;
    border: none;
    background-color: white;
    color: black;
    margin-top: 10px;
    font-weight: 500;
    transition: background-color 0.4s;
  }

  .background-container {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100vh;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .background-container::-webkit-scrollbar {
    display: none;
  }

  .background-container-faq {
    width: 100%;
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    height: 100%;
    background-image: url("../src/assets/background.png");
    background-position: center;
    background-size: cover;
  }

  .section-main {
    scroll-snap-align: start;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /* max-width: 1400px; */
    margin: 0px auto;
    /* padding: 40px 75px; */
  }

  .fixed-buttons-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 50px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1000;
  }

  .centered {
    justify-content: center;
  }

  .header-container {
    width: 1400px;
    background: rgb(240, 241, 242);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }

  .toolbar-button {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: none;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    margin-right: 10px;
    transition: opacity 2.3s ease, transform 3.3s ease;
  }

  .toolbar-button-black {
    padding: 10px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid white;
    background: black;
    color: white;
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
  }

  .toolbar-button.visible {
    opacity: 1;
    transform: scale(1);
  }

  .other-button:hover {
    background-color: white;
    color: black;
  }

  .support-button:hover {
    background-color: white;
    color: black;
  }

  .shadow-4 {
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* tabsolution */
  .solution-tabs {
    margin-top: 100px;
    width: 1400px;
    margin-right: auto;
    margin-left: auto;
  }

  .tab-container {
    width: 1400px;
    height: 790px;
    margin-left: auto;
    margin-right: auto;
  }

  .header-intro {
    margin-top: 100px;
    color: white;
    margin-bottom: -80px;
  }

  /* //star */
  .ssl-container-out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 100vh;
  }

  .ssl-header {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
  }

  .ssl-container {
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    width: 1400px;
  }

  .ssl-img-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    margin-bottom: 20px;
  }

  .ssl-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 620px;
    width: 100%;
    margin-left: 40px;
  }

  .pr-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .pr-p {
    margin-bottom: 10px;
    text-align: justify;
    /* font-size: 12px; */
    font-family: "'Open Sans',blinkmacsystemfont,'Segoe UI',roboto,'Helvetica Neue',helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei',simsun,sans-serif";
  }

  /* product component */

  .starlink-card {
    background: none;
    width: 100%;
    height: 440px;
    border-radius: 12px;
  }

  .st-padding {
    padding: 40px;
  }

  .starlink-card:hover {
    box-shadow: 0px 0px 10px 0px white(0, 0, 0, 0.5);
  }

  .st-tab {
    color: white;
    width: 700100%px;
  }

  .white-color {
    color: white;
  }

  .starlink-li {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 9px; */
  }

  .flex-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    /* margin-bottom: 20px; */
  }

  .flex-img-container {
    width: 100px;
    height: 400px;
    margin-top: 65px;
    margin-left: 20px;
  }

  .starlink-desc-fizik {
    margin-top: 10px;
    width: 85%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 40px;
  }

  .icon-cell {
    width: 100px;
    height: 80px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-cell2 {
    width: 60px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .starlink-icon {
    max-width: 100%;
    max-height: 100%;
  }

  .starlink-desc {
    margin-top: 10px;
    width: 70%;
    height: 30px;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .starlink-p1 {
    width: 30%;
    margin-left: 20px;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .starlink-p {
    width: 70%;
    justify-content: flex-start;
    justify-content: flex-start;
    font-size: 11px;
    font-family: Helvetica, Arial, sans-serif;
  }

  .padding-flex {
    padding: 20px;
  }

  /* intro */
  .pr-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  }

  .pr-50-inline {
    width: 100%;
    padding: 70px;
    position: relative;
    display: inline-block;
  }

  .pr-header {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 26px;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 400;
  }

  .pr-img {
    width: 450px;
    height: 450px;
    border-radius: 10px;
  }

  .pr-desc {
    width: 100%;
    margin-right: 60px;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    text-align: justify;
  }

  .pr-p {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
  }

  .pr-p-2 {
    text-align: justify;
    font-family: Helvetica Neue, sans-serif, Arial;
    font-weight: 300;
  }

  .tech-cont {
    display: flex;
    flex-direction: row;
    padding: 20px;
  }

  .tech-ul-1 {
    width: 300px;
    font-size: 16px;
  }

  .tech-ul-2 {
    margin-left: 40px;
    font-size: 16px;
  }

  .fizik-cont {
    display: flex;
    flex-direction: row;
  }

  .fz-1 {
    margin-top: 100px;
    margin-left: 10px;
    font-size: 12px;
  }

  .fz-2 {
    width: 200px;
    margin-left: -30px;
  }

  .fz-3 {
    text-align: center;
    margin-left: 40px;
    width: 160px;
    font-size: 10px;
  }

  .fz-4 {
    width: 125px;
    height: 10px;
    margin-left: 40px;
  }

  .fz-img {
    width: 120px;
    height: 180px;
  }

  .fz-text {
    text-align: center;
    font-size: 12px;
  }

  .fz-c-2 {
    width: 160px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .width-150 {
    width: 150px;
  }

  .fz-img-2 {
    width: 220px;
    height: 50px;
  }

  .fz-top-t {
    text-align: end;
    justify-content: flex-end;
    width: 60px;
    margin-top: -90px;
    font-size: 10px;
  }

  .fz-5 {
    margin-top: 120px;
    margin-left: 90px;
    font-size: 10px;
  }

  .fz-6 {
    margin-top: 12px;
  }

  /* footer */

  .footer {
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
    bottom: 0px;
  }

  .footer-container2 {
    margin-top: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    width: 1200px;
    /* width: "100%"; */
    margin: 0px auto;
    bottom: 0px;
  }

  .flex-row2 {
    display: flex;
    flex-direction: row;
  }

  .footer-sub {
    width: 430px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: 100px; */
  }

  .f-12-family {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
  }

  .w-250-flex {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footers {
    width: 1100px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .footer-name {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: 20px;
    color: white;
  }

  .f-line {
    border-left: 1px solid white;
    margin-right: 20px;
    margin-left: 3px;
  }

  /* footerL */
  .fl-container {
    height: 100%;
    width: 100%;
    background-color: #f7f9fa;
  }

  .fl-sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin: 0px auto;
    width: 1400px;
  }

  .fl-sub-1 {
    display: flex;
    flex-direction: row;
    height: 20px;
  }

  .fl-img {
    height: 20px;
  }

  .fl-txt {
    color: #000;
    padding: 10px;
    font-size: 14px;
    margin-top: -2px;
    margin-left: 28px;
    cursor: pointer;
  }

  .fl-sub-2 {
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
  }

  .fl-imgg {
    width: 20px;
  }

  .fl-img-2 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
  }

  .fl-img-3 {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    margin-left: 5px;
  }

  .fl-hr {
    /* margin-top: 10px; */
  }

  .fl-hr-s {
    color: #a3abb1;
    display: flex;
    margin: 0px auto;
    justify-content: center;
    align-items: center;
    width: 1400px;
    height: 1px;
  }

  .fl-cont-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    width: 1400px;
    background-color: #f7f9fa;
  }

  .fl-add {
    color: #000;
    padding: 10px;
    font-size: 14px;
  }

  .fl-phone {
    color: #000;
    padding: 10px;
    margin-right: -10px;
    font-size: 14px;
  }

  /* product list */

  .bfl-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    margin: 0 10px;
    overflow: hidden;
  }

  .bfl-card-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .bfl-card-img2 {
    display: block;
    margin-top: 60px;
    margin-left: 60px;
    width: 60%;
    height: 60%;
  }

  .bfl-card-desc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    padding: 20px;
  }

  /* faq */

  .faq2-section {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    margin-top: 75px;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }

  .faq2-container {
    margin-top: 40px;
    background-color: #000312;
    /* max-width: 1400px; */
    /* height: 75vh; */
    overflow-y: auto;
    margin-bottom: 30px;
  }

  .faq2-section::-webkit-scrollbar {
    display: none;
  }

  .faq2-h3 {
    font-size: 18px;
    line-height: 25px;
    color: white;
    margin-top: auto;
  }

  .faq2-ul {
    flex-basis: 58.333%;
    max-width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .faq2-li {
    /* position: relative;  */
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    -webkit-transition-property: border;
    transition-property: border;
    -webkit-transition-duration: 0.3s, 0.3s;
    transition-duration: 0.3s, 0.3s;
    cursor: pointer;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: row;
    padding: 15px;
    /* padding: 10px; */
  }

  .faq2-div {
    transform: rotate(180deg);
    transition-property: all;
    transition-duration: 0.3s, 0.3s;
  }

  .faq2-q {
    /* width: 1200px; */
    text-align: left;
    color: white;
    font-size: 16px;
    width: 100%;
    justify-content: center;
    margin-bottom: 0rem;
  }

  .faq2-q.clicked {
    font-weight: 500;
    /* text-decoration: underline; */
    color: #000;
  }

  .faq2-desc {
    /* display: flex;
    flex-direction: row; */
    justify-content: left;
    align-items: flex-start;
    width: 1000px;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }

  .faq2-desc.clicked {
    background-color: white;
  }

  .paragraph.clicked {
    background-color: white;
    color: #000;
  }

  .faq2-p {
    color: black;
    font-size: 16px;
    display: flex;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0px !important;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .faq2-footer {
    width: 100%;
    height: 180px;
    background-color: #f7f9fa;
    margin-top: auto;
  }

  .faq2-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    max-width: 1400px;
    margin: 0 auto;
  }

  .margin-left-28 {
    margin-left: 28px;
    margin-top: -5px;
  }

  /* Footer text styles */
  .faq2-footer-text {
    color: black;
    padding: 10px;
    font-size: 14px;
  }

  .faq-number {
    margin-right: -20px;
  }

  /* Footer contact styles */
  .faq2-footer-contact {
    display: flex;
    flex-direction: row;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
  }

  .faq2-footer-contact img {
    height: 100%;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
  }

  .bf-select-item {
    border-radius: 10px;
    width: 700px;
    font-family: "Open Sans", blinkmacsystemfont, "Segoe UI", roboto,
      "Helvetica Neue", helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", simsun, sans-serif;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .bf-col-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid lightgrey;
    padding: 10px;
  }

  /* .bf-col-1:hover {
    border: 1px solid blue;
    border-radius: 5px;
  } */

  .bf-col-1.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin: 10px;
    border: 1px solid #808080;
    border-radius: 10px;
  }

  .bf-c-main:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-c-main.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bf-col-2 {
    display: flex;
    flex-direction: row;
    padding: 30px;
    border: 1px solid #f0f1f2;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease;
  }

  .bf-col-2:hover {
    border: 1px solid blue;
    border-radius: 5px;
  }

  .bf-col-2.clicked {
    border: 2px solid blue;
    border-radius: 5px;
  }

  .bf-row-1 {
    /* position: relative; */
    max-width: 1380px;
    margin: 0px auto;
  }

  .ant-tabs-ink-bar {
    /* display: none !important; */
    border: 1px solid #1d1d1f !important;
  }

  .counter-container {
    display: flex;
    align-items: center;
    width: 100px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    height: 40px;
  }

  .counter-container button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
  }

  .counter-container input {
    width: 60px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom: none;
    border-top: none;
    border-radius: 0%;
    color: #000;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-top>div>.ant-tabs-nav::before,
  :where(.css-dev-only-do-not-override-95jhdm).ant-tabs-bottom>div>.ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    content: "";
  }

  .link-text {
    width: 200px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
  }

  .link-text:hover {
    color: blue;
  }

  .marginTop5 {
    margin-top: 5px;
  }

  /* buymain */
  .buy-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    padding: 20px;
  }

  .buy-menu:hover {
    background-color: #f2f2f2;
  }

  .buy-menu.clicked {
    background-color: #f2f2f2;
  }

  .buy-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e6e3e5;
    /* background-color: #f2f2f2; */
    padding: 20px;
    height: 550px;
    width: 400px;
  }

  .buy-second-item {
    margin-top: 0px;
  }

  .buy-third-item {
    margin-top: 0px;
  }

  .buy-item:hover {
    background-color: #fafafa;
  }

  .buy-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f2f2f2; */
    background-color: #d7d3d3;
    padding: 20px;
    height: 270px;
    width: 230px;
  }

  .buy-grid-item:hover {
    background-color: #fafafa;
  }

  .show-all {
    margin-bottom: 7px;
  }

  .show-all:hover {
    text-decoration: underline;
  }

  .videofull {
    width: 100%;
    height: 100%;
  }

  .l-menu {
    cursor: pointer;
  }

  .l-menu:hover {
    text-decoration: underline;
  }

  .buy-button-container {
    height: 8vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 100;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  .footer-b-container {
    /* height: 8vh; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: flex-end; */
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: white;
    z-index: 100;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }

  .bfooter-container {
    height: 6vh;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(153, 119, 8, 0);
    color: #a3abb1;
    z-index: 100;
  }

  .buy-filter-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e4e6e8;
    padding: 20px;
  }

  .buy-filter-item:hover {
    background-color: #fafafa;
  }

  .buy-main-filter-item {
    margin-left: 890px;
    margin-top: 220px;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    padding: 10px;
    border-radius: 5px;
  }

  .buy-main-filter-item-l {
    margin-bottom: 10px;
    font-size: 14px;
    width: 200px;
  }

  .buy-main-filter-item-l:hover {
    font-weight: 600;
  }

  .buy-main-filter-item-l.selected {
    font-weight: 600;
  }

  .buy-filter-img {
    height: 100%;
    width: 100%;
  }

  .buy-filter-img:hover {
    height: 100%;
    width: 100%;
  }

  .vertical-line {
    width: 0.2px;
    height: 680px;
    margin-top: 20px;
    background-color: grey;
  }

  /* buy-main  */
  .buy-out {
    max-width: 1400px;
    margin: 0px auto;
    height: 100vh;
  }

  .buy-main-container {
    background-color: white;
    margin: 0px auto;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    max-height: 100vh;
    overflow-y: auto;
  }

  .buy-main-container::-webkit-scrollbar {
    display: none;
  }

  .bs-container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0px auto;
    max-height: 740px;
    overflow-y: auto;
  }

  .buy-main-d-f {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin: 0px auto;
  }

  .bm-filter {
    margin-top: 80px;
  }

  .buy-menu-dropdown-container {
    display: none;
  }

  .container-scroll {
    scroll-snap-type: y mandatory;
    overflow-y: auto;
    /* overflow: hidden; */
    height: 100vh;
  }

  .container-scroll::-webkit-scrollbar {
    display: none;
  }

  .buy-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 3px;
  }

  .bm-container-filter {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    height: 550px;
  }

  .bm-container-filter-item {
    display: flex;
    flex-direction: row;
    height: 400px;
    justify-content: center;
    align-items: center;
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-18 {
    font-size: 18px;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-left-40 {
    margin-left: 40px;
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .margin-top-5 {
    margin-top: 5px;
  }

  .margin-top-40 {
    margin-top: 40px;
  }

  .margin-top-18 {
    margin-top: 18px;
  }

  .margin-bottom-20 {
    margin-bottom: 20px;
  }

  .height-550 {
    height: 550px;
  }

  .height-400 {
    height: 400px;
  }

  .filter {
    filter: grayscale(100%) brightness(40%);
  }

  .buy-main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 80px;
    margin-left: 40px;
    margin-bottom: 5px;
  }

  .display-row {
    display: flex;
    flex-direction: row;
  }

  .display-column {
    display: flex;
    flex-direction: column;
  }

  .b-link-text {
    margin-left: 10px;
    margin-top: -2px;
    height: 10px;
    width: 10px;
  }

  .buy-main-name {
    margin-top: 20px;
    font-size: 22px;
    text-align: center;
  }

  .amount {
    margin-top: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 4px;
    margin-bottom: 10px;
  }

  .buy-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;
    margin-left: 7px;
  }

  .buy-grid-additional {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 7px;
    margin-left: 7px;
  }

  .buy-img-cont {
    height: 150px;
    width: 150px;
    padding: 20px;
  }

  .buy-item-img {
    height: 390px;
    width: 390px;
    padding: 10px;
  }

  .buy-item-name {
    font-size: 14px;
    max-height: 60px;
    text-align: center;
    margin-bottom: 5px;
  }

  .ic-45 {
    height: 45px;
    width: 45px;
  }

  .add-list {
    margin-top: 40px;
    max-width: 1400px;
    margin: 0px auto;
  }

  .add-title {
    margin-left: 40px;
    color: #000;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    margin: 0px auto;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 60px;
  }

  .add-img-cont-1 {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 130px;
  }

  .i-cs {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    height: 100%;
    width: 100%;
  }

  .add-sub {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0px auto;
    transition: transform 0.9s ease-in-out;
  }

  .add-img {
    height: 100%;
    width: 100%;
    padding: 30px;
    object-fit: cover;
  }

  .add-c-1 {
    margin-left: 20px;
  }

  /* buy-flight */
  .bs-label {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 1px solid black;
    color: black;
    border-radius: 10px;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 25px;
  }

  .bs-label.selected {
    background-color: rgba(0, 0, 0, 0.85);
    border: none;
    color: white;
  }

  .bs-label-img-cont {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .bs-tab-content {
    width: 100%;
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: "#ececec";
  }

  .href-cont {
    width: 1400px;
    margin: 0px auto;
  }

  .href-text {
    padding: 50px;
    margin-bottom: -50px;
    margin-top: 40px;
  }

  .bs-container {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0px auto;
    max-height: 740px;
    overflow-y: auto;
  }

  .bs-second-container {
    display: flex;
    flex-direction: column;
    width: 656px;
    margin-left: 20px;
  }

  .width-656 {
    width: 656px;
  }

  .bf-container-tabs {
    width: 656px;
  }

  .height-740 {
    height: 740px;
  }

  .tab-height {
    height: 740px;
  }

  .bs-second-container-header {
    font-size: 32px;
  }

  .bs-header {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    margin-right: 4px;
    margin-bottom: 10px;
  }

  .bs-icon {
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  .br-10 {
    border-radius: 10px;
  }

  .br-grey {
    border: 1px solid grey;
  }

  .bs-header-text {
    background-color: #f7f9fa;
    padding: 10px;
    border-radius: 10px;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .color-3b3e40 {
    color: #3b3e40;
  }

  .bs-check-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    list-style: none;
    margin-bottom: 10px;
  }

  .bs-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
  }

  .bs-check-cont {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .bs-check-cont-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 40px;
    width: 320px;
  }

  .bs-check-cont-2-name {
    font-size: 20px;
    text-align: start;
    margin: 10px 0px 0px;
    color: black;
  }

  .bs-check-cont-2-amount {
    font-size: 18px;
    text-align: center;
    color: #000;
  }

  .bs-hr {
    width: 1400px;
    color: #3b3e40;
    display: flex;
    margin: 0px auto;
  }

  .mr-30 {
    padding: 30px;
  }

  .bs-s-cont {
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin: 0px auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .bs-s-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
  }

  .bs-s-desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
    text-align: justify;
    font-size: 14px;
  }

  .bs-s-dot {
    width: 8px;
    height: 8px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
    margin-right: 10px;
  }

  .bs-s-sub {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 300px;
    height: 240px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
    justify-content: flex-end;
    margin: 0px auto;
  }

  .bs-s-sub-header {
    font-size: 18px;
    display: flex;
    flex-direction: column;
  }

  .bs-video-cont {
    display: flex;
    flex-direction: row;
    width: 1400px;
    height: 500px;
    margin: 0px auto;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .bs-video-cont-img-1 {
    width: 790px;
    height: 100%;
  }

  .bs-video-cont-img-c {
    margin-left: 20px;
  }

  .bs-video-cont-img {
    width: 570px;
    height: 100%;
    margin-left: 10px;
  }

  .bs-price-cont {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .bs-pric-txt {
    font-size: 24px;
    color: #000;
  }

  .bs-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    bottom: 0px;
    height: 30px;
    width: 200px;
    font-size: 12px;
  }

  .bs-button2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    bottom: 0px;
    height: 30px;
    width: 150px;
    font-size: 12px;
  }

  .bs-button3 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 12px;
    margin-left: 20px;
    bottom: 0px;
    height: 30px;
    width: 150px;
    font-size: 12px;
  }

  /* AllProduct.css */

  .all-product-container {
    height: 140vh;
  }

  .all-product-content {
    background-color: white;
    width: 1400px;
    margin: 0 auto;
  }

  .all-product-header {
    margin-top: 80px;
  }

  .all-product-main {
    display: flex;
    flex-direction: row;
    height: 100vh;
    margin: 0 auto;
  }

  .all-filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
  }

  .all-filter-second {
    display: none;
  }

  .all-product-sidebar {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .all-product-sidebar-h3 {
    /* font-family: "Helvetica, Arial, sans-serif"; */
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .all-product-sidebar-secondary {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -10px;
    /* margin-top: 40px; */
  }

  .all-product-sidebar-item {
    width: 300px;
  }

  .all-product-sidebar-item-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  .all-product-sidebar-item-img {
    height: 15px;
    width: 15px;
    margin-left: 20px;
  }

  .all-product-sidebar-item-radio {
    width: 100%;
    margin-top: 10px;
  }

  .all-product-header-2 {
    font-size: 16px;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: -10px;
  }

  .all-product-main-content {
    margin-left: 40px;
  }

  .all-product-main-content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }

  .all-product-main-content-header .filter-button {
    cursor: pointer;
    color: blue;
    background-color: rgba(0, 0, 0, 0.04);
    width: 220px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }

  .all-product-grid-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
  }

  .all-product-main-content-header .filter-item {
    display: flex;
    flex-direction: column;
  }

  .all-product-main-content-header .filter-item .selected {
    background-color: lightgray;
  }

  .all-product-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .all-product-item {
    cursor: pointer;
  }

  .all-product-item img {
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .all-product-item-name {
    font-size: 14px;
    height: 50px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    width: 200px;
    font-weight: 400px;
  }

  .all-product-item-price {
    font-size: 18px;
    font-weight: 600px;
  }

  .bs-row-1-cont {
    /* margin-right: 30px; */
    height: 518px;
    margin-left: 10px;
  }

  /* rent */
  .rent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 800px;
  }

  .rent-name {
    margin-top: 40px;
    font-size: 30px;
  }

  .rent-products {
    width: 1400px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 800px;
  }

  .rent-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    padding: 100px;
    margin-right: 20px;
    margin-left: 20px;
    height: 600px;
    width: 95%;
    border-radius: 10px;
  }

  .rent-item:hover {
    /* opacity: 0.6; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(158, 158, 160), 0 0 10px rgb(208, 205, 212),
      0 0 10px white, 0 0 10px gray;
  }

  .rent-item.selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
  }

  .rent-image {
    height: 400px;
    width: 400px;
  }

  .rent-check-cont {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .rent-add-item {
    width: 460px;
    color: #000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .rent-range {
    margin-left: 30px;
    border: 3px solid black;
  }

  .rent-price-detail {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
  }

  .rent-product-title {
    font-size: 24px;
    margin-top: 20px;
    text-align: center;
  }

  .sp-select-item {
    border-radius: 10px;
    font-family: "Open Sans", blinkmacsystemfont, "Segoe UI", roboto,
      "Helvetica Neue", helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", simsun, sans-serif;
    font-weight: 300;
    letter-spacing: -0.02em;
  }

  .sc-container {
    width: 1400px;
    margin: 0px auto;
    /* padding: 80px; */
    margin-top: 100px;
    margin-left: 30px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
  }

  .sc-sub-container {
    width: 1400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .sc-sub-container-header {
    font-size: 28px;
  }

  .sc-check-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    list-style: none;
    margin-bottom: 10px;
    /* width: 1400px; */
  }

  .sc-check-grid-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }

  .sc-check-grid-name {
    width: 500px;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .sc-check-grid-name-image {
    height: 50px;
    width: 50px;
  }

  .sc-check-grid-name-text {
    color: black;
    margin-left: 20px;
  }

  .sc-check-grid-price {
    width: 100px;
    color: #000;
    text-align: center;
  }

  .sc-check-grid-counter {
    width: 140px;
    height: 30px;
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .sc-check-grid-total {
    width: 100px;
    color: #000;
    text-align: center;
  }

  .sc-check-grid-button {
    height: 30px;
    width: 50px;
    text-align: center;
  }

  .sc-form {
    display: flex;
    flex-direction: row;
    width: 1400px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .sc-form-font {
    font-size: 18px;
  }

  :where(.css-dev-only-do-not-override-95jhdm).ant-form-item .ant-form-item-label>label {
    font-size: 18px;
  }
  .contact-main {
    background-color: white;
    width: 1400px;
    margin: 0px auto;
  }
  .contact-second {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 40px;
  }
  .contact-third {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact-img {
    height: 80px;
    width: 80px;
  }
  .contact-card {
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px;
    list-style: none;
    margin-bottom: 20px;
    margin-top: 60px;
  }
  .c-card-item {
    padding: 16px;
  }
  .c-card-item-h {
   height:  160px;
  }
  .c-card-body {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .c-card-img {
    width: 40px;
    height: 20px;
  }
  .contact-address {
    display: flex;
    flex-direction: column;
  }
  .contact-address-t {
    margin-left: 10px;
    height: 70px;
  }
  .contact-address2 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: flex-start;
  }
  .contact-a-img {
    height: 10px;
    width: 10px;
    align-items: self-start;
  }
  .contact-fb {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
  }
  .contact-fb-embed {
    height: 520px;
    width: 460px;
  }
  .contact-insta-embed {
    height: 520px;
    width: 440px;
    margin-left: 30px;
  }
  .contact-tw-embed {
    height: 520px;
    width: 440px;
    margin-left: 50px;
  }
}

.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: 0.001em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.carousel-control-prev-icon {
  background-image: url("https://www.svgrepo.com/show/12963/left-arrow.svg") !important;
  margin-top: 10px;
  border-radius: 50%;
}

.carousel-control-next-icon {
  background-image: url("https://www.svgrepo.com/show/166617/right-arrow.svg") !important;
}

.custom-input {
  color: black !important;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 1s infinite;
}

:where(.css-dev-only-do-not-override-95jhdm).ant-select .ant-select-arrow {
  color: blue;
}

.flying-number {
  position: fixed;
  color: red;
  font-size: 24px;
  z-index: 1000;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.fly-to-cart {
  transform: translate(800px, -400px);
  opacity: 0;
}